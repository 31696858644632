import { navigateToHome } from 'utils'
import React from 'react'
import cn from 'clsx'
import { useNavigate } from 'react-router-dom'
import BackArrow from 'assets/icons/back_arrow.svg'

const Header = ({ renderLogo = false }) => {
  const navigate = useNavigate()
  return (
    <div className="w-full max-w-[480px] block mx-auto p-6 bg-white rounded-b-[8px]">
      {renderLogo ? (
        <img
          src="https://imagedelivery.net/V1rHMrkE5rLIFJ8jdBoCpA/177ed539-f96e-4c63-d8d2-ba6fe9d7f000/public"
          className="w-[160px] h-auto"
          alt="logo-kasanak"
          onClick={navigateToHome}
        />
      ) : (
        <div
          className={cn({
            'cursor-pointer': true,
            'flex flex-row': !renderLogo
          })}
          onClick={() => navigate(-1)}>
          <img src={BackArrow} alt="back-arrow" />
          <p className="text-[#0C0C0C] font-bold pl-3">Daftar Kas Anak</p>
        </div>
      )}
    </div>
  )
}

export default Header
