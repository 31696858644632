import { Routes, Route, Navigate } from 'react-router-dom'
import Layout from 'containers/Layout'
import Landing from 'containers/Landing'
import Asuransi from 'containers/Asuransi'
// import Pendidikan from 'containers/Pendidikan'

const App = () => {
  return (
    <Layout>
      <Routes>
        <Route path="/" element={<Landing />} />
        <Route path="/asuransi" element={<Asuransi />} />
        {/* <Route path="/pendidikan" element={<Pendidikan />} /> */}
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </Layout>
  )
}

export default App
