import React from 'react'
import Tabungan from 'components/Landing/Tabungan'
import Header from 'components/common/Header'

const Landing = () => (
  <>
    <Header renderLogo={true} />
    <Tabungan />
  </>
)

export default Landing
