import clsx from 'clsx'

import styles from './DataList.module.css'

const DataList = ({
  name,
  label,
  inputClassName,
  labelClassName,
  type,
  dataList,
  placeholder,
  register,
  watch,
  onChange,
  required,
  maxLength,
  minLength,
  max,
  min,
  pattern,
  validate,
  valueAsNumber,
  valueAsDate,
  setValueAs,
  shouldUnregister,
  errors
}) => {
  const inputValue = watch(name)

  return (
    <>
      <label
        className={clsx('relative text-sm font-bold', labelClassName)}
        htmlFor={name}
        aria-label="input-label">
        {label && (
          <span className={clsx(styles.label, inputValue && styles.isFilled)}>{label}</span>
        )}
        <select
          {...register(name, {
            required,
            maxLength,
            minLength,
            max,
            min,
            pattern,
            validate,
            valueAsNumber,
            valueAsDate,
            setValueAs,
            shouldUnregister
          })}
          className={clsx(
            styles['custom-select'],
            errors && errors[name] && 'border border-[#C94432]',
            inputValue && 'bg-[#F9F6FC] border border-solid border-[#863DC4]'
          )}>
          <option value="" disabled selected hidden>
            {placeholder}
          </option>
          {dataList.map(({ id, value, label }) => (
            <option
              key={id}
              value={value}
              className="text-[#2B2B2B] text-sm font-medium m-2 bg-[#FEFEFE]">
              {label}
            </option>
          ))}
        </select>

        {errors && errors[name] ? (
          <div className="text-xs text-[#C94432] mt-2">{errors[name].message}</div>
        ) : null}
      </label>
    </>
  )
}

export default DataList
