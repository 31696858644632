import React from 'react'
import GeneralInfo from 'components/Asuransi/GeneralInfo'
import Form from 'components/Asuransi/Form'
import Header from 'components/common/Header'

const Asuransi = () => (
  <>
    <Header />
    <Form />
  </>
)

export default Asuransi
