export const simulasiInvestasi = (calcTotalTabungan) => {
    const investMatrix = new Map();
    if (calcTotalTabungan === 0 || calcTotalTabungan < 100000) {
      return investMatrix;
    }
    const interestRate = (2.5 / 100) / 12; // 2.5% per year
    const mfiRate = (1.5 / 100) / 12; // 1.5% per year
    const totalMonths = 10 * 12; // 10 years
    const investAmount = calcTotalTabungan;
    var investRolledAmount = investAmount;

    for (let i = 0; i < totalMonths; i++) {
      if (i > 0) {
        investRolledAmount += investAmount;
      }
      let rate =  Math.round(investRolledAmount * interestRate);
      investRolledAmount += rate;
      investRolledAmount = Math.round(investRolledAmount * (1 - mfiRate));
      investMatrix.set(i, {
        "investment": investRolledAmount,
        "interest": investRolledAmount - ((i +1) * investAmount)
      })
    }

    return investMatrix;
}
