import BottomSheet from 'components/common/BottomSheet'
import PartyInformation from './PartyInformation'
import IconDiasuransikan from 'assets/icons/ic_diasuransikan.svg'
import IconAhliWaris from 'assets/icons/ic_ahli_waris.svg'
import IconPemegangPolis from 'assets/icons/ic_pemegang_polis.svg'

const ConfirmationBottomSheet = ({ isOpen, onClose, pyd, onSubmit }) => {
  return (
    <BottomSheet isOpen={isOpen} onClose={onClose}>
      <div className="px-4 pt-16 pb-4">
        <p className="text-[#0C0C0C] font-black text-xl mb-8">
          Selanjutnya, kamu akan melengkapi data di bawah ini.{' '}
          <span className="text-[#863DC4]">Cek kembali kesesuaiannya</span>.
        </p>

        <div className="border border-solid border-[#D8D8D8] rounded-lg p-4 shadow-sm mb-8">
          <PartyInformation
            iconSrc={IconDiasuransikan}
            title="Pihak yang diasuransikan"
            subTitle={
              <p className="text-[#0C0C0C] text-xs">
                Pihak yang jika meninggal dunia maka santunan beasiswa akan diberikan (disarankan
                <span className="font-bold"> pemberi nafkah utama</span>)
              </p>
            }
            isRenderAdditionalInfo={true}
            additionalInfo={
              <p className="text-[#2B2B2B] text-xs">
                <span className="font-bold">{pyd === 'self' ? 'Kamu' : 'Pasanganmu'}</span> sebagai
                Pihak yang diasuransikan
              </p>
            }
          />

          <hr className="border-dashed border-[#989898] opacity-50 my-6" />

          <PartyInformation
            iconSrc={IconPemegangPolis}
            title="Pemegang Polis"
            subTitle="Sebagai pemegang akun dan yang membayar kontribusi perlindungan"
            isRenderAdditionalInfo={true}
            additionalInfo={
              <p className="text-[#2B2B2B] text-xs">
                <span className="font-bold">Kamu</span> sebagai Pemegang Polis
              </p>
            }
          />

          <hr className="border-dashed border-[#989898] opacity-50 my-6" />

          <PartyInformation
            iconSrc={IconAhliWaris}
            title="Ahli Waris"
            subTitle="Yang berhak untuk mengurus klaim atas santunan beasiswa anak jika kamu tutup usia"
            isRenderAdditionalInfo={true}
            additionalInfo={
              <p className="text-[#2B2B2B] text-xs">
                <span className="font-bold">{pyd === 'self' ? 'Pasangan' : 'Kamu'}</span>{' '}
                {pyd === 'self' && 'kamu'} sebagai ahli waris
              </p>
            }
          />
        </div>

        <button
          className="w-full text-[#FEFEFE] font-bold px-20 py-3 rounded-lg outline-none focus:outline-none mr-1 ease-linear transition-all duration-150 bg-[#863DC4]"
          onClick={onSubmit}>
          Sudah sesuai, lanjut
        </button>
      </div>
    </BottomSheet>
  )
}

export default ConfirmationBottomSheet
