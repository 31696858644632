import React, { useEffect, useState, useCallback, useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'
import dayjs from 'dayjs'
import { Controller, useForm } from 'react-hook-form'
import { NumericFormat } from 'react-number-format'
import cn from 'clsx'
import ReactSlider from 'react-slider'
import Checkbox from 'react-custom-checkbox'
import { FiCheck } from 'react-icons/fi'
import DataList from 'components/common/DataList'
import PartyInformation from './PartyInformation'
import ConfirmationBottomSheet from './ConfirmationBottomSheet'
import DatePicker from '../common/DatePicker'
import { getCOI, grossUpTotal } from 'utils'
import ChevronUp from 'assets/icons/chevron_up.svg'
import HintIcon from 'assets/icons/hint_icon.svg'
import InfoIcon from 'assets/icons/info_icon.svg'
import IconDiasuransikan from 'assets/icons/ic_diasuransikan.svg'
import IconAhliWaris from 'assets/icons/ic_ahli_waris.svg'
import IconPemegangPolis from 'assets/icons/ic_pemegang_polis.svg'
import { LocalStorageKeys } from 'const/localStorageKey'

export const PYD_OPTION = [
  { id: 'self', value: 'self', label: 'Diri Sendiri' },
  { id: 'spouse', value: 'spouse', label: 'Pasangan' }
]

const Form = () => {
  const {
    control,
    formState: { errors },
    setValue,
    getValues,
    register,
    watch
  } = useForm({
    mode: 'onChange'
  })

  const dob = watch('tanggalLahir')
  const [calcTotalTabungan, setTotalTabungan] = useState(0)
  const [calcTotalDonasi, setTotalDonasi] = useState(0)
  const [calcTotalKontribusi, setTotalKontribusi] = useState(0)
  const [calcSantunan, setCalcSantunan] = useState(0)
  const [calcUsia, setUsia] = useState(0)
  const [calcTotalBulanan, setTotalBulanan] = useState(0)
  const [calcTambahDonasi, setTambahDonasi] = useState(0)
  const [, setGrossUp] = useState(0)
  const [, setDonasiGrossUp] = useState(0)
  const [grossupChecked, setGrossupChecked] = useState(false)
  const [hideGrossup, setHideGrossup] = useState(false)
  const [calcTotalRemainder, setTotalRemainder] = useState(0)
  const [isShowSummaryDetail, setIsShowSummaryDetail] = useState(false)
  const [addDonationChecked, setAddDonationChecked] = useState(false)
  const [isBottomSheetOpen, setIsBottomSheetOpen] = useState(false)
  const [searchParams] = useSearchParams()
  const queryStrings = useMemo(() => searchParams.toString(), [searchParams])

  const openBottomSheet = () => {
    setIsBottomSheetOpen(true)
    console.log('lalaa::')
  }

  const closeBottomSheet = () => {
    setIsBottomSheetOpen(false)
  }

  useEffect(() => {
    const tabungan = JSON.parse(localStorage.getItem(LocalStorageKeys.NOMINAL_TABUNGAN))
    setTotalTabungan(parseInt(tabungan))
  }, [])

  const getTallyURL = () => {
    let url = 'https://form.kasanak.com/'
    process.env.REACT_APP_BUILD_ENV === 'production' ? (url += 'formulir') : (url += 'staging')
    return url
  }

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault()
      window.location.href = `${getTallyURL()}?total=`.concat(
        calcTotalBulanan.toLocaleString('id-ID'),
        '&santunan=',
        calcSantunan.toLocaleString('id-ID'),
        '&tabungan=',
        calcTotalTabungan.toLocaleString('id-ID'),
        '&kontribusi=',
        calcTotalKontribusi.toLocaleString('id-ID'),
        calcTotalDonasi > 0 ? '&donasi=' + calcTotalDonasi.toLocaleString('id-ID') : '',
        '&dob=',
        dayjs(dob).format('YYYY-MM-DD'),
        '&pyd=',
        getValues('pyd'),
        queryStrings && `&${queryStrings}`
      )
    },
    [
      calcTotalBulanan,
      calcSantunan,
      calcTotalTabungan,
      calcTotalKontribusi,
      calcTotalDonasi,
      dob,
      searchParams
    ]
  )

  useEffect(() => {
    const getTotalBulanan = () => {
      if (calcTotalTabungan === 0 || calcSantunan === 0 || calcUsia === 0) {
        return setTotalBulanan(0)
      }

      let tabunganKenaUjroh = calcTotalTabungan
      let tabunganTidakKenaUjroh = calcTotalTabungan
      if (calcTotalTabungan > 300000) {
        tabunganKenaUjroh = 300000
      }
      tabunganTidakKenaUjroh = calcTotalTabungan - tabunganKenaUjroh
      var tabarru = (calcSantunan * getCOI(calcUsia)) / 1000
      const kontribusi = (tabunganKenaUjroh + tabarru) / 0.9 // 0.9 == 1 - 10%
      const totalKontribusi = Math.round(tabunganTidakKenaUjroh + kontribusi)
      setTotalKontribusi(totalKontribusi)
      let tambahDonasi = calcTambahDonasi
      if (calcTambahDonasi < 10000) {
        tambahDonasi = 0
      }

      const totalKontribusiDenganDonasi = totalKontribusi + tambahDonasi
      let grossUp = 0
      let donasiGrossUp = 0

      const remainder = totalKontribusiDenganDonasi % 1000
      setTotalRemainder(remainder)
      setHideGrossup(remainder === 0)

      if (grossupChecked && remainder !== 0) {
        grossUp = grossUpTotal(totalKontribusiDenganDonasi)
        donasiGrossUp = grossUp - totalKontribusiDenganDonasi
        tambahDonasi += donasiGrossUp

        setGrossUp(grossUp)
        setTotalBulanan(grossUp)
        setTotalDonasi(tambahDonasi)
        setDonasiGrossUp(donasiGrossUp)
      } else {
        setTotalBulanan(totalKontribusiDenganDonasi)
        setTotalDonasi(tambahDonasi)
        setGrossUp(totalKontribusiDenganDonasi)
        setDonasiGrossUp(0)
      }
    }

    getTotalBulanan()
  }, [calcTotalTabungan, calcSantunan, calcUsia, calcTambahDonasi, grossupChecked])

  const onChangeDatePicker = useCallback(
    (field) => (e) => {
      const usiaTahunWithPrecision = dayjs().diff(e, 'years', true)
      var usiaTahun = dayjs().diff(e, 'years')
      const diffInDays = Math.round((usiaTahunWithPrecision - usiaTahun) * 365)
      if (diffInDays >= 180) {
        usiaTahun += 1
      }
      setUsia(usiaTahun)
      field.onChange(e)
    },
    [setUsia]
  )

  const datePickerClassName = useMemo(
    () =>
      cn({
        'calendar-icon appearance-none border border-[#C8C8C8] rounded-[8px] w-full py-4 px-3 font-bold focus:outline-none text-sm': true,
        'text-gray focus:border-[#863DC4] focus:bg-[rgba(134,61,196,0.04)]': !errors.tanggalLahir,
        'text-gray !border-[#863DC4] bg-[rgba(134,61,196,0.04)]':
          !errors.tanggalLahir && !!calcUsia,
        'text-[#C44C4C] !border-[#C44C4C] bg-[rgba(196,76,76,0.04)]': !!errors.tanggalLahir
      }),
    [errors.tanggalLahir, calcUsia]
  )

  const disableSubmit =
    !!errors?.jumlahTabungan ||
    !calcTotalTabungan ||
    !!errors?.santunan ||
    !calcSantunan ||
    !!errors?.tanggalLahir ||
    !calcUsia ||
    !getValues('pyd')

  return (
    <div>
      <div className="w-full max-w-[480px] block mx-auto px-4 py-8 bg-white mb-2 shadow-[_inset_0px_12px_10px_-10px_rgba(0,0,0,0.08)]">
        <h6 className="text-[#0C0C0C] text-xl font-black">
          <span className="text-[#863DC4]">Lindungi</span> tabungan pendidikan Anak dengan{' '}
          <span className="text-[#863DC4]">Asuransi Jiwa Syariah</span>
        </h6>
        <p className="text-[#2B2B2B] text-sm py-4">Tentukan siapa pihak yang akan diasuransikan.</p>

        <div className="border border-solid border-[#D8D8D8] rounded-lg p-4 shadow-sm">
          <PartyInformation
            iconSrc={IconDiasuransikan}
            title="Pihak yang diasuransikan"
            subTitle={
              <p className="text-[#0C0C0C] text-xs">
                Pihak yang jika meninggal dunia maka santunan beasiswa akan diberikan (disarankan
                <span className="font-bold"> pemberi nafkah utama</span>)
              </p>
            }
            isRenderAdditionalInfo={false}
          />

          <div>
            <DataList
              dataList={PYD_OPTION}
              name="pyd"
              placeholder="Perlindungan untuk..."
              type="text"
              register={register}
              watch={watch}
              errors={errors}
              required={{
                value: true,
                message: 'Pilih pihak yang diasuransikan.'
              }}
              labelClassName={!getValues('pyd') ? 'text-[#D8D8D8]' : 'text-[#2B2B2B]'}
            />
          </div>

          <div className="mt-4">
            <p className="text-[#0C0C0C] text-xs font-medium pb-1">Tanggal Lahir</p>
            <Controller
              control={control}
              name="tanggalLahir"
              rules={{
                required: { value: true, message: 'Tanggal lahir harus diisi' }
              }}
              valueAsDate="true"
              render={({ field }) => (
                <DatePicker
                  onChange={onChangeDatePicker(field)}
                  selected={field.value}
                  className={datePickerClassName}
                />
              )}
            />
            {errors.tanggalLahir && (
              <span className="text-[#C44C4C] text-sm">{errors.tanggalLahir.message}</span>
            )}
          </div>

          <hr className="border-dashed border-[#989898] opacity-50 my-6" />

          <PartyInformation
            iconSrc={IconPemegangPolis}
            title="Pemegang Polis"
            subTitle="Sebagai pemegang akun dan yang membayar kontribusi perlindungan"
            isRenderAdditionalInfo={true}
            additionalInfo={
              <p className="text-[#2B2B2B] text-xs">
                <span className="font-bold">Kamu</span> sebagai Pemegang Polis
              </p>
            }
          />

          <hr className="border-dashed border-[#989898] opacity-50 my-6" />

          <PartyInformation
            iconSrc={IconAhliWaris}
            title="Ahli Waris"
            subTitle="Yang berhak untuk mengurus klaim atas santunan beasiswa anak jika kamu tutup usia"
            isRenderAdditionalInfo={true}
            additionalInfo={
              <p className="text-[#2B2B2B] text-xs">
                <span className="font-bold">
                  {getValues('pyd') === 'self' ? 'Pasangan' : 'Kamu'}
                </span>{' '}
                {getValues('pyd') === 'self' && 'kamu'} sebagai ahli waris
              </p>
            }
          />
        </div>
      </div>

      <div className="w-full max-w-[480px] block mx-auto px-4 py-8 bg-white">
        <h6 className="text-[#2b2b2b] font-bold mb-4">
          <span className="text-[#863DC4]">Santunan beasiswa pendidikan</span> yang ingin kamu
          siapkan melalui asuransi jiwa syariah
        </h6>
        <Controller
          render={({ field: { onChange, onBlur, name, value, ref } }) => (
            <>
              <div className="mt-4">
                <ReactSlider
                  className="w-full h-7"
                  max={250000000}
                  min={100000000}
                  marks={[100000000, 150000000, 200000000, 250000000]}
                  defaultValue={100000000}
                  step={5000000}
                  onChange={(val) => {
                    setValue('santunan', val)
                    setCalcSantunan(val)
                    onChange({
                      type: 'text',
                      target: { value: val, name }
                    })
                  }}
                  value={calcSantunan || 100000000}
                  thumbClassName="h-full aspect-square rounded-full bg-[#C598E5] cursor-grab border-4 border-[#7324B6]"
                  renderTrack={(props, state) => {
                    const isLast = state.index !== 0
                    const isFirst = state.index === 0
                    return (
                      <div
                        {...props}
                        className={cn({
                          'h-2 top-1/2 -translate-y-1/2 rounded-full': true,
                          'bg-[#E8E8E8]': isFirst ? isLast : isLast,
                          'bg-[#A978D4]': !isFirst ? !isLast : isFirst
                        })}
                      />
                    )
                  }}
                  renderMark={(props) => {
                    switch (props.key) {
                      case 150000000:
                        props.style.left = '31.5%'
                        break
                      case 200000000:
                        props.style.left = '63%'
                        break
                      case 250000000:
                        props.style.left = undefined
                        props.style.right = 0
                        break
                      default:
                    }
                    return (
                      <div
                        {...props}
                        className={cn({
                          'top-1/2 -translate-y-1/2 h-4 w-4 rounded-full cursor-pointer': true,
                          'bg-[#7324B6]': props.key <= (calcSantunan || 0),
                          'bg-[#6A6A6A]': props.key > (calcSantunan || 0)
                        })}
                      />
                    )
                  }}
                />
              </div>
              <div className="relative flex justify-between w-full mt-2 text-sm font-medium">
                <div className="relative">100Jt</div>
                <div className="relative">150Jt</div>
                <div className="relative">200Jt</div>
                <div className="relative">250Jt</div>
              </div>
              <label className="block text-[#0C0C0C] text-xs mb-2 mt-6">
                Atau input berdasarkan keinginan
              </label>
              <NumericFormat
                className={cn({
                  'appearance-none border border-[#C8C8C8] rounded-[8px] w-full py-4 px-3 leading-tight font-bold focus:outline-none text-sm': true,
                  'text-gray focus:border-[#863DC4] focus:bg-[rgba(134,61,196,0.04)]':
                    !errors.santunan,
                  'text-gray !border-[#863DC4] bg-[rgba(134,61,196,0.04)]':
                    !errors.santunan && !!calcSantunan,
                  'text-[#C44C4C] !border-[#C44C4C] bg-[rgba(196,76,76,0.04)]': !!errors.santunan
                })}
                displayType="input"
                type="text"
                decimalSeparator=","
                thousandSeparator="."
                placeholder="Rp100.000.000"
                onValueChange={(v) => {
                  setCalcSantunan(v.floatValue)
                  onChange({
                    type: 'text',
                    target: { value: v.floatValue, name }
                  })
                }}
                name={name}
                value={value}
                onBlur={onBlur}
                ref={ref}
                prefix={'Rp'}
              />
            </>
          )}
          rules={{
            required: { value: true, message: 'Santunan harus diisi' },
            min: { value: 100000000, message: 'Santunan minimal Rp100.000.000' },
            max: { value: 250000000, message: 'Santunan maksimal Rp250.000.000' }
          }}
          defaultValue="100000000"
          name="santunan"
          control={control}
        />
        {errors.santunan && (
          <span className="text-[#C44C4C] text-sm">{errors.santunan.message}</span>
        )}

        <div className="flex flex-row p-2 rounded-lg bg-[#F2FBFE] gap-x-2 mt-4">
          <img src={HintIcon} alt="hint-ic" />
          <p className="text-[#2B2B2B] text-xs">
            Santunan diberikan jika Ayah/Ibu (
            <span className="font-bold">pihak yang diasuransikan</span> ) meninggal dunia
          </p>
        </div>
      </div>
      <div
        className={cn({
          'w-full max-w-[480px] mx-auto bg-[#F2F3F4]': true,
          'pb-[75px]': !isShowSummaryDetail && disableSubmit,
          'pb-[130px]': !isShowSummaryDetail && !disableSubmit,
          'pb-[200px]': isShowSummaryDetail
        })}>
        <div className="w-full p-4 bg-[#F2F3F4] rounded-t-[8px]">
          <h6 className="text-[#2b2b2b] font-bold mb-4">
            Berikut <span className="text-[#863DC4]">nominal yang kamu bayarkan</span> untuk
            tabungan + asuransi jiwa setiap bulan
          </h6>
          <Controller
            render={({ field: { onChange, onBlur, name, value, ref } }) => (
              <NumericFormat
                readOnly={true}
                className={cn({
                  'appearance-none border rounded-[8px] w-full py-4 px-3 leading-tight font-bold focus:outline-none text-sm': true,
                  'text-[#9ca3af]': disableSubmit || !calcTotalBulanan,
                  'text-[#863DC4]': !disableSubmit && !!calcTotalBulanan
                })}
                decimalSeparator=","
                thousandSeparator="."
                prefix="Rp"
                suffix=" / bulan"
                value={disableSubmit ? 0 : calcTotalBulanan}
              />
            )}
            name="totalBulanan"
            control={control}
          />
          {Boolean(calcTotalBulanan) && (
            <div className="flex flex-row p-2 rounded-lg bg-[#F2FBFE] mt-2 gap-x-2">
              <img src={HintIcon} alt="hint-ic" />
              <p className="text-[#2B2B2B] text-xs">
                Atau setara dengan{' '}
                <strong>
                  Rp
                  {(calcTotalBulanan / 30 - ((calcTotalBulanan / 30) % 1000)).toLocaleString(
                    'ID'
                  )}{' '}
                  / hari
                </strong>
              </p>
            </div>
          )}
          <label htmlFor="totalBulanan" className="block text-[#0C0C0C] text-xs mt-2">
            *Sudah Termasuk Biaya Administrasi Anggota Untuk Kas Anak{' '}
            {grossupChecked && calcTotalRemainder !== 0 ? 'dan Donasi ke Kas Anak Yatim' : ''}
          </label>

          {/* <div className="bg-[#FEFEFE] p-3 mt-8 rounded-lg">
            <h6 className="text-[#2b2b2b] text-sm font-bold">
              (Opsional) Donasi Rutin ke Kas Anak Asuh
            </h6>
            <p
              className={cn({
                'block text-[#0C0C0C] text-xs mt-1': true
              })}>
              Kas Anak Asuh adalah program beasiswa & binaan untuk anak-anak dhuafa
            </p>

            {!hideGrossup && (
              <Checkbox
                borderColor={grossupChecked ? '#863DC4' : '#6A6A6A'}
                icon={<FiCheck color="#FEFEFE" />}
                className={cn({
                  'text-[#2b2b2b] mt-4 !h-[18px] !w-[18px]  ': true,
                  'bg-[#863DC4]': grossupChecked
                })}
                labelClassName={cn({
                  'text-[#2b2b2b] text-xs font-bold mt-4 !ml-3': true
                })}
                label="Bulatkan nominal untuk donasi ke Kas Anak Asuh"
                checked={grossupChecked}
                onChange={(value, event) => {
                  setGrossupChecked(value)
                }}
              />
            )}

            <Checkbox
              borderColor={addDonationChecked ? '#863DC4' : '#6A6A6A'}
              icon={<FiCheck color="#FEFEFE" />}
              className={cn({
                'text-[#2b2b2b] mt-4 !h-[18px] !w-[18px]': true,
                'bg-[#863DC4]': addDonationChecked,
                'bg-[#FEFEFE]': !addDonationChecked
              })}
              labelClassName={cn({
                'text-[#2b2b2b] text-xs font-bold mt-4 !ml-3': true
              })}
              label="Tambah donasi ke Kas Anak Asuh"
              checked={addDonationChecked}
              onChange={(value, event) => {
                setAddDonationChecked(value)
                if (!value) {
                  setTambahDonasi(0)
                  setValue('tambahDonasi', 0)
                }
              }}
            />
            {addDonationChecked && (
              <>
                <Controller
                  render={({ field: { onChange, onBlur, name, value, ref } }) => (
                    <NumericFormat
                      className={cn({
                        'appearance-none border border-[#C8C8C8] rounded-[8px] w-full py-4 px-3 leading-tight font-bold focus:outline-none mt-4 text-sm': true,
                        'text-gray focus:border-[#863DC4] focus:bg-[rgba(134,61,196,0.04)]':
                          !errors.tambahDonasi,
                        'text-gray !border-[#863DC4] bg-[rgba(134,61,196,0.04)]':
                          !errors.tambahDonasi && !!calcTambahDonasi,
                        'text-[#C44C4C] !border-[#C44C4C] bg-[rgba(196,76,76,0.04)]':
                          !!errors.tambahDonasi
                      })}
                      displayType="input"
                      type="text"
                      decimalSeparator=","
                      thousandSeparator="."
                      placeholder="Rp10.000"
                      defaultValue="0"
                      onValueChange={(v) => {
                        var val = v.floatValue
                        if (val === undefined || v === '') {
                          val = 0
                        }
                        setTambahDonasi(val)
                        onChange({
                          type: 'text',
                          target: { value: val, name }
                        })
                      }}
                      name={name}
                      value={value}
                      onBlur={onBlur}
                      ref={ref}
                      prefix="Rp"
                    />
                  )}
                  rules={{
                    validate: (v) => {
                      return v === 0 || v >= 10000 || 'Donasi minimal Rp10.000'
                    }
                  }}
                  name="tambahDonasi"
                  control={control}
                />
                {errors.tambahDonasi && (
                  <span className="text-[#C44C4C] text-sm">{errors.tambahDonasi.message}</span>
                )}
              </>
            )}

            <div className="flex flex-row p-2 rounded-lg bg-[#F2FBFE] mt-4 gap-x-2">
              <img src={InfoIcon} alt="hint-ic" />
              <p className="text-[#2B2B2B] text-[10px]">
                Seluruh nominal donasi dikelola oleh Yayasan Kita Bisa
              </p>
            </div>
          </div> */}
        </div>
      </div>

      <div className="shadow-2xl fixed bottom-0 max-w-[480px] w-screen left-[50%] -translate-x-1/2 z-50">
        {!disableSubmit && (
          <div className="flex flex-col p-4 bg-[#FEFEFE] rounded-t-lg">
            <div
              className="flex flex-row items-center justify-between active:bg-none"
              role="button"
              onClick={() => setIsShowSummaryDetail(!isShowSummaryDetail)}>
              <p className="text-[#2B2B2B] text-xs">
                <strong>Detail Pembayaran</strong> (per bulan)
              </p>
              <img
                src={ChevronUp}
                alt="chevron-icon"
                className={cn({
                  'rotate-180': isShowSummaryDetail
                })}
              />
            </div>
            {isShowSummaryDetail && (
              <div>
                <hr className="border-dashed border-[#989898] my-3" />
                <div className="flex flex-col gap-y-3">
                  <div className="flex flex-row gap-x-3 items-center">
                    <span className="w-2 h-2 rounded-[50%] bg-[#863DC4] basis-2" />
                    <p className="text-[#2B2B2B] text-xs basis-3/4">Tabungan</p>
                    <p className="text-[#0C0C0C] text-xs font-bold ml-auto">
                      Rp{calcTotalTabungan.toLocaleString('ID')}
                    </p>
                  </div>
                  <div className="flex flex-row gap-x-3 items-center">
                    <span className="w-2 h-2 rounded-[50%] bg-[#40BAEB] basis-2" />
                    <p className="text-[#2B2B2B] text-xs basis-3/4">
                      Asuransi Jiwa + Biaya Anggota
                    </p>
                    <p className="text-[#0C0C0C] text-xs font-bold ml-auto">
                      Rp{(calcTotalKontribusi - calcTotalTabungan).toLocaleString('ID')}
                    </p>
                  </div>
                  {grossupChecked || calcTambahDonasi ? (
                    <div className="flex flex-row gap-x-3 items-center">
                      <span className="w-2 h-2 rounded-[50%] bg-[#26AFAF] basis-2" />
                      <p className="text-[#2B2B2B] text-xs basis-3/4">Donasi Kas Anak Asuh</p>
                      <p className="text-[#0C0C0C] text-xs font-bold ml-auto">
                        Rp{calcTotalDonasi.toLocaleString('ID')}
                      </p>
                    </div>
                  ) : null}
                </div>
              </div>
            )}
          </div>
        )}

        <div
          className={cn({
            'p-4 flex flex-row gap-x-4': true,
            'bg-[#F9F6FC]': !disableSubmit,
            'bg-[#FEFEFE] rounded-t-3xl': disableSubmit
          })}>
          <div className="basis-1/2 flex flex-col gap-y-1">
            <p className="text-[#2B2B2B] font-bold text-xs">Total pembayaran</p>
            <p className="text-[#863DC4] font-bold text-sm">
              {disableSubmit
                ? '-'
                : `Rp${disableSubmit ? 0 : calcTotalBulanan.toLocaleString('ID')} / bulan`}
            </p>
          </div>
          <div className="basis-1/2">
            <button
              className={cn({
                'w-full text-[#FEFEFE] font-bold px-20 py-3 rounded-lg outline-none focus:outline-none mr-1 ease-linear transition-all duration-150': true,
                'bg-[#D8D8D8]': disableSubmit,
                'bg-[#863DC4]': !disableSubmit
              })}
              disabled={disableSubmit}
              onClick={openBottomSheet}>
              Lanjut
            </button>
          </div>
        </div>
      </div>
      <ConfirmationBottomSheet
        isOpen={isBottomSheetOpen}
        onClose={closeBottomSheet}
        pyd={getValues('pyd')}
        onSubmit={handleSubmit}
      />
    </div>
  )
}

export default Form
