import React from 'react'
import ReactDatePicker, { registerLocale } from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import id from 'date-fns/locale/id'
import { getMaxDate, getMinDate } from 'utils'

registerLocale('id', id)

const DatePicker = ({ onChange, selected, className }) => {
  const DatePickerInput = ({ value, onClick, onChange: inputOnChange }) => (
    <input
      value={value}
      onClick={onClick}
      onChange={inputOnChange}
      className={className}
      placeholder="Pilih tanggal"
    />
  )

  return (
    <ReactDatePicker
      showMonthDropdown
      showYearDropdown
      dropdownMode="select"
      locale="id"
      placeholderText="Pilih tanggal"
      customInput={<DatePickerInput />}
      onChange={onChange}
      minDate={getMinDate()}
      maxDate={getMaxDate()}
      selected={selected}
      dateFormat="dd MMMM yyyy"
    />
  )
}

export default DatePicker
