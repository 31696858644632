import Sheet from 'react-modal-sheet'

const BottomSheet = ({ children, isOpen = false, onClose, snapPoints = [750] }) => {
  return (
    <Sheet
      disableDrag
      isOpen={isOpen}
      onClose={onClose}
      snapPoints={snapPoints}
      className="w-full max-w-[480px] block mx-auto">
      {isOpen && (
        <>
          <Sheet.Container>
            <Sheet.Content>{children}</Sheet.Content>
          </Sheet.Container>
          <Sheet.Backdrop onTap={onClose} />
        </>
      )}
    </Sheet>
  )
}

export default BottomSheet
