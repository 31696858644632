export const getCOI = (age) => {
  let coi = 0
  if (age >= 16 && age <= 21) {
    coi = 0.08
  }
  switch (age) {
    case 22:
      coi = 0.09
      break
    case 23:
    case 24:
      coi = 0.1
      break
    case 25:
    case 26:
      coi = 0.11
      break
    case 27:
      coi = 0.12
      break
    case 28:
    case 29:
      coi = 0.13
      break
    case 30:
      coi = 0.14
      break
    case 31:
      coi = 0.15
      break
    case 32:
    case 33:
      coi = 0.16
      break
    case 34:
      coi = 0.17
      break
    case 35:
    case 36:
      coi = 0.18
      break
    case 37:
      coi = 0.19
      break
    case 38:
      coi = 0.2
      break
    case 39:
    case 40:
      coi = 0.21
      break
    case 41:
      coi = 0.22
      break
    case 42:
      coi = 0.25
      break
    case 43:
      coi = 0.27
      break
    case 44:
      coi = 0.31
      break
    case 45:
      coi = 0.35
      break
    case 46:
      coi = 0.4
      break
    case 47:
      coi = 0.45
      break
    case 48:
      coi = 0.52
      break
    case 49:
      coi = 0.59
      break
    case 50:
      coi = 0.67
      break
    case 51:
      coi = 0.77
      break
    case 52:
      coi = 0.87
      break
    case 53:
      coi = 0.98
      break
    case 54:
      coi = 1.09
      break
    case 55:
      coi = 1.2
      break
    case 56:
      coi = 1.31
      break
    case 57:
      coi = 1.43
      break
    case 58:
      coi = 1.54
      break
    case 59:
      coi = 1.65
      break
    case 60:
      coi = 1.77
      break
    case 61:
      coi = 1.9
      break
    case 62:
      coi = 2.05
      break
    case 63:
      coi = 2.22
      break
    default:
  }
  return coi
}
