import React, { useState, useMemo } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { NumericFormat } from 'react-number-format'
import ReactSlider from 'react-slider'
import { useNavigate, useSearchParams } from 'react-router-dom'
import cn from 'clsx'
import HintIcon from 'assets/icons/hint_icon.svg'
import { simulasiInvestasi } from 'utils'
import { LocalStorageKeys } from 'const/localStorageKey'

const Tabungan = () => {
  const {
    control,
    formState: { errors },
    setValue
  } = useForm({
    mode: 'onChange'
  })

  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const queryStrings = useMemo(() => searchParams.toString(), [searchParams])

  const [totalTabungan, setTotalTabungan] = useState(0)
  const [totalTahun, setTotalTahun] = useState(10)

  const getSimulasi = simulasiInvestasi(totalTabungan)

  const renderDetail = !errors?.jumlahTabungan && totalTabungan

  const handleSubmit = () => {
    localStorage.setItem(LocalStorageKeys.NOMINAL_TABUNGAN, JSON.stringify(totalTabungan))
    navigate(`/asuransi${queryStrings && `?${queryStrings}`}`)
  }

  return (
    <div className="w-full h-screen max-w-[480px] block mx-auto px-4 py-8 bg-white mb-2 shadow-[_inset_0px_12px_10px_-10px_rgba(0,0,0,0.08)]">
      <h6 className="text-[#0C0C0C] font-black text-xl pb-4">
        Hitung <span className="text-[#863DC4]">tabungan pendidikan</span> Anak
      </h6>
      <p className="text-[#2B2B2B] text-sm pb-4">
        Hitung dan dapatkan estimasi hasil tabunganmu dalam 10 tahun kedepan
      </p>

      <div>
        <div className="rounded-t-lg bg-[#40BAEB] p-3">
          <p className="text-[#FEFEFE] text-xs font-bold ">Tabungan per bulanmu</p>
        </div>
        <div className="rounded-b-lg bg-[#FEFEFE] p-3 drop-shadow-xl">
          <Controller
            render={({ field: { onChange, onBlur, name, value, ref } }) => (
              <NumericFormat
                className={cn({
                  'appearance-none border border-[#C8C8C8] rounded-[8px] w-full py-4 px-3 leading-tight font-bold focus:outline-none text-sm': true,
                  'text-gray focus:border-[#863DC4] focus:bg-[rgba(134,61,196,0.04)]':
                    !errors.jumlahTabungan,
                  'text-gray !border-[#863DC4] bg-[rgba(134,61,196,0.04)]':
                    !errors.jumlahTabungan && !!totalTabungan,
                  'text-[#C44C4C] !border-[#C44C4C] bg-[rgba(196,76,76,0.04)]':
                    !!errors.jumlahTabungan
                })}
                displayType="input"
                type="text"
                decimalSeparator=","
                thousandSeparator="."
                placeholder="Tabungan / bulan"
                onValueChange={(v) => {
                  setTotalTabungan(v.floatValue)
                  onChange({
                    type: 'text',
                    target: { value: v.floatValue, name }
                  })
                }}
                name={name}
                value={value}
                onBlur={onBlur}
                ref={ref}
                prefix="Rp"
                suffix=" / bulan"
              />
            )}
            rules={{
              required: { value: true, message: 'Tabungan rutin harus diisi' },
              min: { value: 100000, message: 'Tabungan rutin minimal Rp100.000' }
            }}
            name="jumlahTabungan"
            control={control}
          />

          {errors.jumlahTabungan ? (
            <span className="text-[#C44C4C] text-xs">{errors.jumlahTabungan.message}</span>
          ) : (
            <span className="text-[#2B2B2B] text-xs font-medium pt-1">Min. Rp100.000</span>
          )}

          {Boolean(renderDetail) && (
            <div className="mt-5 border-t border-dashed border-[#989898] mx-[-12px] px-3 py-5">
              <div className="flex flex-row justify-between items-center">
                <p className="text-[#2B2B2B] font-bold text-xs">Estimasi tabunganmu</p>
                <p className="text-[#863DC4] font-black">
                  Rp{getSimulasi?.get(totalTahun * 12 - 1)?.investment?.toLocaleString('ID')}
                </p>
              </div>

              <Controller
                render={({ field: { onChange, onBlur, name, value, ref } }) => (
                  <>
                    <div className="mt-8 mb-11">
                      <ReactSlider
                        className="w-full h-5"
                        max={10}
                        min={1}
                        marks={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]}
                        defaultValue={10}
                        step={1}
                        onChange={(val) => {
                          setValue('tahunTabungan', val)
                          setTotalTahun(val)
                          onChange({
                            type: 'text',
                            target: { value: val, name }
                          })
                        }}
                        value={totalTahun || 10}
                        thumbClassName="h-full aspect-square rounded-full bg-[#C598E5] cursor-grab border-4 border-[#7324B6]"
                        renderThumb={(props, state) => {
                          switch (state.value) {
                            case 10:
                              props.style.left = undefined
                              props.style.right = 0
                              break
                            default:
                          }
                          return (
                            <>
                              <div {...props} />
                              <p
                                style={props.style}
                                className="mt-6 text-[#2B2B2B] text-xs font-bold">
                                Tahun ke {state.value}
                              </p>
                            </>
                          )
                        }}
                        renderTrack={(props, state) => {
                          const isLast = state.index !== 0
                          const isFirst = state.index === 0
                          return (
                            <div
                              {...props}
                              className={cn({
                                'h-2 top-1/2 -translate-y-1/2 rounded-full': true,
                                'bg-[#E8E8E8]': isFirst ? isLast : isLast,
                                'bg-[#A978D4]': !isFirst ? !isLast : isFirst
                              })}
                            />
                          )
                        }}
                        renderMark={(props) => {
                          switch (props.key) {
                            case 1:
                              props.style.height = 12
                              props.style.width = 12
                              break
                            case 10:
                              props.style.left = undefined
                              props.style.right = 0
                              break
                            default:
                          }
                          return (
                            <div
                              {...props}
                              className={cn({
                                'top-1/2 -translate-y-1/2 h-2 w-2 rounded-full cursor-pointer': true,
                                'bg-[#7324B6]': props.key <= (totalTahun || 0),
                                'bg-[#6A6A6A]': props.key > (totalTahun || 0)
                              })}
                            />
                          )
                        }}
                      />
                    </div>
                  </>
                )}
                name="tahunTabungan"
                control={control}
              />

              <div className="p-2 bg-[#F2FBFE] flex flex-row items-center gap-x-2">
                <img src={HintIcon} alt="hint-ic" />
                <p className="text-[#2B2B2B] text-[10px]">
                  Asumsi hasil investasi dari nabung:{' '}
                  <span className="font-bold"> 2.5% per tahun</span>
                </p>
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="shadow-2xl fixed bottom-0 max-w-[480px] w-screen left-[50%] -translate-x-1/2 z-50 p-4">
        <button
          className={cn({
            'w-full text-[#FEFEFE] font-bold px-20 py-3 rounded-lg outline-none focus:outline-none mr-1 ease-linear transition-all duration-150': true,
            'bg-[#D8D8D8]': !renderDetail,
            'bg-[#863DC4]': renderDetail
          })}
          disabled={!renderDetail}
          onClick={handleSubmit}>
          Lanjut
        </button>
      </div>
    </div>
  )
}

export default Tabungan
