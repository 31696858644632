export const getMaxDate = () => {
  const dateCopy = new Date()
  dateCopy.setFullYear(dateCopy.getFullYear() - 16)
  dateCopy.setDate(dateCopy.getDate() - 180)
  return dateCopy
}

export const getMinDate = () => {
  const dateCopy = new Date()
  dateCopy.setFullYear(dateCopy.getFullYear() - 59)
  dateCopy.setDate(dateCopy.getDate() - 179)
  return dateCopy
}
