import HintIcon from 'assets/icons/hint_icon.svg'

const PartyInformation = ({ iconSrc, title, subTitle, isRenderAdditionalInfo, additionalInfo }) => (
  <>
    <div className="flex flex-row pb-4">
      <img src={iconSrc} alt="diasuransikan-icon" />
      <div className="pl-4">
        <p className="text-[#0C0C0C] text-sm font-bold pb-1">{title}</p>
        <p className="text-[#0C0C0C] text-xs">{subTitle}</p>
      </div>
    </div>
    {isRenderAdditionalInfo && (
      <>
        <div className="flex flex-row p-2 rounded-lg bg-[#F2FBFE] gap-x-2">
          <img src={HintIcon} alt="hint-ic" />
          <p className="text-[#2B2B2B] text-xs">{additionalInfo}</p>
        </div>
      </>
    )}
  </>
)

export default PartyInformation
